<template>
  <div class="card">
    <span id="loading" v-if="this.loading == true">
      <Loading />
    </span>
    <h1>Adicionar Produto</h1>
    <hr />
    <form v-on:submit.prevent="addProduct()">
      <div class="row">
        <div class="col">
          <label for="name">Nome:</label>
          <input id="name" type="text" class="form-control" v-model="name" required />
        </div>
        <div class="col">
          <label for="validade">Validade:</label>
          <br />
          <date-picker
            style="width: 100%"
            v-model="validade"
            type="month"
            format="YYYY-MM"
            value-type="format"
            :lang="lang"
          ></date-picker>
          <span id="infinity" @click="setDataInfinity()"> Não tem validade </span>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <label for="sku">SKU:</label>
          <input id="sku" type="text" class="form-control" v-model="sku" required />
        </div>
        <div class="col">
          <label for="ean">EAN:</label>
          <input id="ean" type="text" class="form-control" v-model="ean" required />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <label for="weight">Peso (g):</label>
          <input id="weight" type="text" class="form-control" v-model="weight" required />
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col-sm-10"></div>
        <div class="col-sm-2">
          <button class="btn btn-primary mb-2" type="submit">Adicionar</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Loading from '@/components/Loading.vue';

export default {
  components: {
    Loading,
  },
  data: function () {
    return {
      loading: false,
      name: '',
      price: '1.00',
      sku: '',
      ean: '',
      stock: 0,
      weight: '',
      validade: '',
      tax_iva: '23',
      lang: {
        formatLocale: {
          firstDayOfWeek: 1,
          months: [
            'janeiro',
            'fevereiro',
            'março',
            'abril',
            'maio',
            'junho',
            'julho',
            'agosto',
            'setembro',
            'outubro',
            'novembro',
            'dezembro',
          ],
          // MMM
          monthsShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
          // dddd
          weekdays: [
            'Domingo',
            'Segunda-feira',
            'Terça-feira',
            'Quarta-feira',
            'Quinta-feira',
            'Sexta-feira',
            'Sábado',
          ],
          // ddd
          weekdaysShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
          // dd
          weekdaysMin: ['Do', 'Se', 'Te', 'Qa', 'Qi', 'Se', 'Sa'],
        },
      },
    };
  },
  methods: {
    async setDataInfinity() {
      console.log('Infinito');
      this.validade = '2050-01';
    },
    async addProduct() {
      try {
        this.loading = true;
        await this.$store.dispatch('addProduct', {
          name: this.name,
          price: this.price,
          sku: this.sku,
          ean: this.ean,
          stock: this.stock,
          weight: this.weight,
          validade: this.validade + '-01',
          tax_iva: this.tax_iva,
        });
        this.$swal({
          toast: true,
          position: 'top-end',
          icon: 'success',
          title: 'Produto Adicionado!!',
          showConfirmButton: false,
          timer: 1500,
        });
      } catch (err) {
        this.$swal({
          icon: 'error',
          title: 'Ooops',
          text: err,
        });
        console.log(err);
      } finally {
        this.loading = false;
        this.name = '';
        this.sku = '';
        this.ean = '';
        this.stock = '';
        this.weight = '';
        this.validade = '';
      }
    },
  },
  created() {},
  computed: {},
};
</script>

<style scoped>
.card {
  padding: 25px;
  background-color: rgba(255, 147, 147, 0.733);
  margin: 50px;
  border: 1px solid #dddd;
  border-radius: 18px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.row {
  margin-bottom: 15px;
}
hr {
  background-color: white;
  height: 2px;
  border: 0;
}
h3 {
  color: white;
}
#send {
  text-align: right;
}
.canceled {
  background-color: red;
  border-color: rgb(255, 0, 0);
}

#infinity:hover {
  cursor: pointer;
  color: aqua;
}
</style>
